<template>
  <vue-modal @close="$emit('close')" ref="modal" size="sm">
    <template #title>Categoría</template>
    <template #body>
      <FormText
        label="Categoria"
        icon="fa-solid fa-tag"
        v-model="form.categoria"
      />
    </template>
    <template #footer>
      <button class="btn btn-primary" @click="saveData">Guardar</button>
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";

export default {
  components: {
    FormText,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      categoria: "",
    },
  }),
  async mounted() {
    if (this.item) {
      this.form = {...this.item};
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveData() {
      const id = this.form.id
      const form = {...this.form}
      if (this.form.id) {
        this.$store
          .dispatch("updateCategoriaTorneo", {
            id,
            form,
          })
          .then(() => {
            this.close();
          });
      } else {
        this.$store.dispatch("createCategoriaTorneo", form).then(() => {
          this.close();
        });
        this.$emit('saved')
      }
    },
  },
};
</script>
<style></style>
